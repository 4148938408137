import React from 'react';
import { useTranslation } from 'react-i18next'; // Importa o hook de tradução
import logofooter from '../src/assets/logoquickflowai.png';
import { FaShieldAlt, FaPercent, FaStethoscope, FaPhoneAlt } from 'react-icons/fa'; // Ícones
import tiktokLogo from '../src/assets/TikTok-Marketing-Partner-1.png';
import metaLogo from '../src/assets/Group-39465.svg';
import googleLogo from '../src/assets/Group-39464.svg';
import { TiArrowUpOutline } from "react-icons/ti";

function Footer() {
  const { t } = useTranslation(); // Hook de tradução

  return (
    <footer>
      {/* Primeira Seção com um div ocupando 100% da largura */}
      <div style={{ textAlign: 'center' }}>
        <button
          type="submit"
          className="conversion-btn flex items-center mx-auto"
          onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
        >
          <TiArrowUpOutline className="mr-2" />
          {t('letsTalk')}
        </button>
      </div>

      <div style={{ marginBottom: '40px' }}></div>

      {/* Parcerias */}
      <div className="bg-gray-200 pt-10 pb-10 px-6">
        <h3 className="text-2xl font-bold text-center mb-6">
          {t('ourPartnerships')}
        </h3>
        <div className="flex flex-wrap justify-center gap-8">
          <div className="w-full md:w-1/4 text-center">
            <img src={tiktokLogo} alt="TikTok Marketing Partner" className="w-40 mx-auto" />
          </div>
          <div className="w-full md:w-1/4 text-center">
            <img src={googleLogo} alt="Google Marketing Partner" className="w-40 mx-auto" />
          </div>
          <div className="w-full md:w-1/4 text-center">
            <img src={metaLogo} alt="Meta Marketing Partner" className="w-40 mx-auto" />
          </div>
        </div>
      </div>

      {/* Achievements */}
      <div
        className="bg-black text-white py-16 px-8 shadow-2xl text-center relative overflow-hidden bg-cover bg-no-repeat"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${process.env.PUBLIC_URL}/assets/pexels-photo-220067.webp')`
        }}
      >
        <h2 className="text-4xl font-extrabold mb-6 relative inline-block">
          <span className="absolute inset-0 h-1 w-full bg-white -bottom-2 left-0 transform -skew-x-12"></span>
          {t('achievements')}
        </h2>

        <div className="relative z-10 max-w-3xl mx-auto mt-8">
          <p className="text-xl mb-8 leading-relaxed tracking-wide">
            {t('achievementsDescription')}
          </p>

          <p className="text-2xl font-semibold border-l-4 border-yellow-500 pl-4 py-4 bg-gray-800 bg-opacity-50 mb-6">
            {t('makePeopleSmile')}
          </p>
        </div>

        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-yellow-400 to-red-500"></div>
        <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-yellow-400 to-red-500"></div>

        <button
          className="bg-white text-black font-bold py-4 px-8 rounded-full text-lg lg:text-xl hover:bg-[#CCFE01] hover:text-black transition-all duration-200 shadow-md"
          onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
        >
          {t('workWithUs')}
        </button>
      </div>

      {/* Segunda Seção com logo e texto */}
      <section className="footer-info">
        <div className="footer-container">
          <div className="footer-logo">
            <img src={logofooter} alt="Logo Fidelidade" />
          </div>

          <div style={{ textAlign: 'center' }} className="block lg:hidden">
            <button
              type="submit"
              className="conversion-btn flex items-center mx-auto"
              onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
            >
              <TiArrowUpOutline className="mr-2" />
              {t('dontWaste')}
            </button>
          </div>

          <div className="footer-text" style={{ textAlign: 'center' }}>
            <p>
              Avenida Coronel Eduardo Galhardo, 1170-105 Lisbon, Lisbon, Portugal
              <br />
              <b>{t('callUs')}:</b> +351 937 458 548
              <br />
              <b>{t('startProject')}:</b> waiting@quickflowai.com
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
