import React, { useState, useEffect } from 'react';
import { FaArrowRight, FaGlobe, FaRobot, FaBolt, FaChartLine } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importa o hook de tradução
import './BenefitsGrid.css';

const RotatingText = () => {
  const { t } = useTranslation(); // Hook de tradução
  const [index, setIndex] = useState(0);
  const texts = [t('paidMedia'), t('creative'), t('dataGrowth')]; // Textos rotativos com tradução

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <div className="rotating-container">
      <h1 className="main-text">
        {t('weDo')} <span className="rotating-text">{texts[index]}</span>
        <br />
        <span className="flex items-center justify-center" style={{ fontSize: "14px" }}>
          <FaGlobe className="mr-2" /> {t('worldwide')}
        </span>
      </h1>
    </div>
  );
};

const BenefitsGrid = () => {
  const { t } = useTranslation(); // Hook de tradução
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 200 && rect.bottom >= 200) {
          setActiveSection(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <RotatingText />

      <section className={`c-labeled-text u-comp-def--1 -flex -index--4 layout--2 after:text-with-logos before:big-list ${activeSection === 0 ? 'active' : ''}`} data-comp="labeled-text" id="comp4" data-inview="" data-inview-scope="">
        <div className="c_inner u-wrapper u-wrapper--1 u-wrapper--pad" data-inview-child="">
          <div className="content_wrapper">
            <div style={{
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#FFFFFF',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
              backgroundColor: '#000080',
              padding: '20px',
              borderRadius: '10px',
              transform: 'rotate(-2deg)',
              border: '5px solid #00FF00',
              margin: '20px auto',
              maxWidth: '80%',
            }}>
              <h1 style={{
                fontSize: '36px',
                textTransform: 'uppercase',
                letterSpacing: '2px',
                margin: '0 0 10px',
                animation: 'flicker 1.5s infinite alternate',
              }}>
                {t('whatWeDo')}
              </h1>
              <h2 style={{
                fontSize: '28px',
                textTransform: 'uppercase',
                letterSpacing: '2px',
                margin: '0 0 20px',
                animation: 'flicker 2s infinite alternate',
              }}>
                {t('withYou')}
              </h2>
              {t('partnersBenefit')}
            </div>
          </div>
        </div>
      </section>

      {/* Section 1 */}
      <section className={`c-big-list list-1 u-comp-def--1 -flex -index--5 after:labeled-text before:big-list ${activeSection === 1 ? 'active' : ''}`} data-comp="big-list" data-cursor="-inverse" id="comp5" data-inview="" data-inview-scope="">
        <div className="c_inner u-wrapper u-wrapper--1 u-wrapper--pad" data-inview-child="">
          <div className="list_wrapper">
            <div className="left_column">
              <div className="heading">
                <h2 className="flex items-center">
                  <FaRobot className="mr-2 accent-dot" /> {t('paidOrganicMedia')}
                  <span className="accent-dot">.</span>
                </h2>
              </div>
            </div>
            <div className="right_column">
              <div className="text">
                <p>{t('mediaDescription')}</p>
              </div>
              <div className="list">
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#2CC157' }} />
                  <p>{t('paidSocial')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#2CC157' }} />
                  <p>{t('paidSearch')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#2CC157' }} />
                  <p>{t('seo')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 */}
      <section className={`c-big-list list-2 u-comp-def--1 -flex -index--5 after:big-list before:big-list ${activeSection === 2 ? 'active' : ''}`} data-comp="big-list" data-cursor="-inverse" id="comp6" data-inview="" data-inview-scope="">
        <div className="c_inner u-wrapper u-wrapper--1 u-wrapper--pad" data-inview-child="">
          <div className="list_wrapper">
            <div className="left_column">
              <div className="heading">
                <h2 className="flex items-center">
                  <FaBolt className="mr-2 accent-dot-pink" /> {t('creative')}
                  <span className="accent-dot-pink">.</span>
                </h2>
              </div>
            </div>
            <div className="right_column">
              <div className="text">
                <p>{t('creativeDescription')}</p>
              </div>
              <div className="list">
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#EDB9FF' }} />
                  <p>{t('creativeStrategy')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#EDB9FF' }} />
                  <p>{t('adCreative')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#EDB9FF' }} />
                  <p>{t('contentShoots')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#EDB9FF' }} />
                  <p>{t('ugc')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3 */}
      <section className={`c-big-list list-3 u-comp-def--1 -flex -index--5 after:big-list before:labeled-text ${activeSection === 3 ? 'active' : ''}`} data-comp="big-list" data-cursor="-inverse" id="comp7" data-inview="" data-inview-scope="">
        <div className="c_inner u-wrapper u-wrapper--1 u-wrapper--pad" data-inview-child="">
          <div className="list_wrapper">
            <div className="left_column">
              <div className="heading">
                <h2 className="flex items-center">
                  <FaChartLine className="mr-2 text-black accent-dot-blue" /> {t('dataScience')}
                  <span className="accent-dot-blue">.</span>
                </h2>
              </div>
            </div>
            <div className="right_column">
              <div className="text">
                <p>{t('dataScienceDescription')}</p>
              </div>
              <div className="list">
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#36C5F0' }} />
                  <p>{t('attributionModelling')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#36C5F0' }} />
                  <p>{t('lifetimeValueAnalysis')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#36C5F0' }} />
                  <p>{t('barkInsights')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BenefitsGrid;
