// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Traduções
const resources = {
  en: {
    translation: {
      welcome: "The traditional digital model",
      welcome2: "is broken.",
      nearLocation: "I think you might be near the outskirts of",
      nearLocation1: "Start making a difference there.",
      funnel: "A full funnel service",
      formtile: "Redefine Your Rules with AI",
      step1Message: "What’s your name?",
      step2Message: "Complete the form",
      RequestaQuote: "Request a Quote",
      step3Message: "describe what you need.",
      nextStep: "NEXT STEP",
      requestQuote: "REQUEST QUOTE",
      thankYouMessage: "Thank you for requesting a quote.",
      aiElevate: "Let AI experts elevate your strategy.",
      aiElevate1: "Request your proposal now and see the results!",
      phoneNumber: "Phone Number",
      observations: "Briefly describe the project and its objectives",
      fullName: "Full Name",
      loadingMessage: "I am waiting to hear more...",
      successMessage: "Our team will get in touch with you soon with our best offer.",
      letsTalk: "Let's Talk – No Time to Waste!",
      ourPartnerships: "Our Partnerships",
      achievements: "What We've Achieved",
      achievementsDescription: "With a team ranging from 1 to 20 years of experience, we’ve achieved many, many millions. We could boast about numbers here, but we prefer to let our work speak for itself. Our clients? Always confidential.",
      makePeopleSmile: "But above all, we’ve made people smile.",
      workWithUs: "Work With Our Amazing Team",
      dontWaste: "Don't waste a moment",
      callUs: "Give us a Call",
      startProject: "Begin writing your next project",
      weDo: "WE DO",
      worldwide: "Worldwide",
      paidMedia: "Paid Media",
      creative: "Creative",
      dataGrowth: "Data.Growth.",
      whatWeDo: "What We Do",
      withYou: "With You",
      partnersBenefit: "Our partners benefit from a disruptive, AI-powered team of media buyers, creatives, and data scientists who work seamlessly to transform both your paid media and organic search. By breaking conventional boundaries, we push your growth targets beyond expectations and redefine what's possible across every digital channel.",
      
      // Section 1
      paidOrganicMedia: "Paid & Organic Media",
      mediaDescription: "Implement a full-funnel strategy across Paid Social and Paid Search to build awareness and drive revenue.",
      paidSocial: "Paid Social",
      paidSearch: "Paid Search",
      seo: "SEO",
      
      // Section 2
      creativeDescription: "Create high-performing ads that build brand and power your performance.",
      creativeStrategy: "Creative Strategy",
      adCreative: "Ad Creative",
      contentShoots: "Content Shoots",
      ugc: "UGC",
      
      // Section 3
      dataScience: "Data Science",
      dataScienceDescription: "Unlock new avenues for growth with tech-enabled insights that solve your blind spots.",
      attributionModelling: "Attribution Modelling",
      lifetimeValueAnalysis: "Lifetime Value Analysis",
      barkInsights: "Bark.Insights",
      NextStep: "Next Step",
      didYouKnow: "Did you know:",
      notificationText: "We craft brands that people buy into and campaigns that make them take action. We make people care about your brand using the power of human connection.",
      readyToFeel: "Are you ready to make people feel something?",
      yes: "Yes"
    }
  },
  pt: {
    translation: {
      didYouKnow: "Sabia que:",
      notificationText: "Criamos marcas que as pessoas abraçam e campanhas que as fazem agir. Fazemos com que as pessoas se importem com a sua marca usando o poder da conexão humana.",
      readyToFeel: "Está pronto para fazer as pessoas sentirem algo?",
      yes: "Sim",
      step3Message: "descreva o que precisa.",
      welcome: "O modelo digital convencional",
      welcome2: "já não funciona.",
      RequestaQuote: "Pedir Proposta",
      NextStep: "Próximo Passo",
      nearLocation: "Penso que estás nas proximidades de:",
      nearLocation1: "Este é o local perfeito para começares a fazer a diferença!",
      funnel: "Serviço de funil completo",
      formtile: "Redefine as Regras com IA",
      step1Message: "Qual é o seu nome?",
      step2Message: "Complete o formulário",
      nextStep: "PRÓXIMO PASSO",
      requestQuote: "SOLICITAR ORÇAMENTO",
      thankYouMessage: "Obrigado por solicitar um orçamento.",
      aiElevate: "Deixe os especialistas em IA elevar a sua estratégia.",
      aiElevate1: "Solicite o seu orçamento agora e veja os resultados!",
      phoneNumber: "Número de Telefone",
      observations: "Descreva brevemente o projeto e os seus objetivos",
      fullName: "Nome Completo",
      loadingMessage: "Estou a aguardar para saber mais...",
      successMessage: "A nossa equipa entrará em contacto consigo em breve com a nossa melhor oferta.",
      letsTalk: "Vamos Conversar – Não Há Tempo a Perder!",
      ourPartnerships: "As Nossas Parcerias",
      achievements: "O Que Alcançámos",
      achievementsDescription: "Com uma equipa com 1 a 20 anos de experiência, conquistámos muitos milhões. Poderíamos gabar-nos de números, mas preferimos deixar o nosso trabalho falar por si. Os nossos clientes? Sempre confidenciais.",
      makePeopleSmile: "Mas acima de tudo, fizemos as pessoas sorrirem.",
      workWithUs: "Trabalhe Com a Nossa Equipa Incrível",
      dontWaste: "Não perca tempo",
      callUs: "Ligue-nos",
      startProject: "Comece a planear o seu próximo projeto",
      weDo: "FAZEMOS",
      worldwide: "Mundialmente",
      paidMedia: "Publicidade Paga",
      creative: "Criatividade",
      dataGrowth: "Dados e Crescimento",
      whatWeDo: "O Que Fazemos",
      withYou: "Consigo",
      partnersBenefit: "Os nossos parceiros beneficiam de uma equipa inovadora, impulsionada por IA, composta por especialistas em publicidade, criativos e cientistas de dados que trabalham em conjunto para transformar tanto a sua publicidade paga quanto a sua pesquisa orgânica. Ao quebrar os limites convencionais, superamos as metas de crescimento e redefinimos o que é possível em todos os canais digitais.",
      
      // Secção 1
      paidOrganicMedia: "Publicidade Paga & Orgânica",
      mediaDescription: "Implemente uma estratégia de funil completo no Paid Social e na Pesquisa Paga para aumentar o reconhecimento e gerar receita.",
      paidSocial: "Publicidade em Redes Sociais",
      paidSearch: "Pesquisa Paga",
      seo: "SEO",
      
      // Secção 2
      creativeDescription: "Crie anúncios de alto desempenho que constroem a marca e impulsionam o seu desempenho.",
      creativeStrategy: "Estratégia Criativa",
      adCreative: "Publicidade Criativa",
      contentShoots: "Produção de Conteúdo",
      ugc: "Conteúdo Gerado pelo Utilizador (UGC)",
      
      // Secção 3
      dataScience: "Ciência de Dados",
      dataScienceDescription: "Descubra novas oportunidades de crescimento com insights habilitados por tecnologia que resolvem os seus pontos cegos.",
      attributionModelling: "Modelagem de Atribuição",
      lifetimeValueAnalysis: "Análise de Valor ao Longo da Vida",
      barkInsights: "Bark.Insights"
    },
  },
  es: {
    translation: {
      didYouKnow: "¿Sabías que:",
      notificationText: "Creamos marcas en las que la gente confía y campañas que los hacen actuar. Hacemos que la gente se preocupe por tu marca usando el poder de la conexión humana.",
      readyToFeel: "¿Estás listo para hacer que la gente sienta algo?",
      yes: "Sí",
      step3Message: "describe lo que necesitas.",
      welcome: "El modelo digital tradicional",
      welcome2: "ya no funciona.",
      RequestaQuote: "Solicitar un Presupuesto",
      NextStep: "Próximo Paso",
      nearLocation: "Creo que podrías estar cerca de las afueras de",
      nearLocation1: "¡Comienza a marcar la diferencia allí!",
      funnel: "Servicio completo de embudo",
      formtile: "Redefine Tus Reglas con IA",
      step1Message: "¿Cuál es tu nombre?",
      step2Message: "Completa el formulario",
      nextStep: "PRÓXIMO PASO",
      requestQuote: "SOLICITAR PRESUPUESTO",
      thankYouMessage: "Gracias por solicitar un presupuesto.",
      aiElevate: "Deja que los expertos en IA eleven tu estrategia.",
      aiElevate1: "¡Solicita tu propuesta ahora y ve los resultados!",
      phoneNumber: "Número de Teléfono",
      observations: "Describe brevemente el proyecto y sus objetivos",
      fullName: "Nombre Completo",
      loadingMessage: "Estoy esperando saber más...",
      successMessage: "Nuestro equipo se pondrá en contacto contigo pronto con nuestra mejor oferta.",
      letsTalk: "¡Hablemos – No Hay Tiempo Que Perder!",
      ourPartnerships: "Nuestras Alianzas",
      achievements: "Lo Que Hemos Logrado",
      achievementsDescription: "Con un equipo que va de 1 a 20 años de experiencia, hemos logrado muchos millones. Podríamos alardear de números aquí, pero preferimos que nuestro trabajo hable por sí solo. ¿Nuestros clientes? Siempre confidenciales.",
      makePeopleSmile: "Pero sobre todo, hemos hecho sonreír a la gente.",
      workWithUs: "Trabaja Con Nuestro Increíble Equipo",
      dontWaste: "No pierdas un momento",
      callUs: "Llámanos",
      startProject: "Comienza a escribir tu próximo proyecto",
      weDo: "HACEMOS",
      worldwide: "Mundialmente",
      paidMedia: "Medios Pagados",
      creative: "Creatividad",
      dataGrowth: "Datos.Crecimiento.",
      whatWeDo: "Lo Que Hacemos",
      withYou: "Contigo",
      partnersBenefit: "Nuestros socios se benefician de un equipo disruptivo, impulsado por IA, de compradores de medios, creativos y científicos de datos que trabajan sin problemas para transformar tanto tus medios pagados como tu búsqueda orgánica. Al romper los límites convencionales, superamos tus objetivos de crecimiento y redefinimos lo que es posible en todos los canales digitales."
    }
  },
  fr: {
    translation: {
      didYouKnow: "Le saviez-vous:",
      notificationText: "Nous créons des marques auxquelles les gens adhèrent et des campagnes qui les incitent à agir. Nous faisons en sorte que les gens se soucient de votre marque grâce au pouvoir de la connexion humaine.",
      readyToFeel: "Êtes-vous prêt à faire ressentir quelque chose aux gens?",
      yes: "Oui",
      step3Message: "décrivez ce dont vous avez besoin.",
      welcome: "Le modèle numérique traditionnel",
      welcome2: "ne fonctionne plus.",
      RequestaQuote: "Demander un devis",
      NextStep: "Étape Suivante",
      nearLocation: "Je pense que vous êtes peut-être près des environs de",
      nearLocation1: "Commencez à faire la différence ici.",
      funnel: "Service complet d'entonnoir",
      formtile: "Redéfinissez Vos Règles avec l'IA",
      step1Message: "Quel est votre nom?",
      step2Message: "Complétez le formulaire",
      nextStep: "ÉTAPE SUIVANTE",
      requestQuote: "DEMANDER UN DEVIS",
      thankYouMessage: "Merci d'avoir demandé un devis.",
      aiElevate: "Laissez les experts en IA améliorer votre stratégie.",
      aiElevate1: "Demandez votre proposition maintenant et voyez les résultats!",
      phoneNumber: "Numéro de Téléphone",
      observations: "Décrivez brièvement le projet et ses objectifs",
      fullName: "Nom Complet",
      loadingMessage: "J'attends de savoir plus...",
      successMessage: "Notre équipe vous contactera bientôt avec notre meilleure offre.",
      letsTalk: "Parlons-en – Pas de temps à perdre!",
      ourPartnerships: "Nos Partenariats",
      achievements: "Ce Que Nous Avons Réalisé",
      achievementsDescription: "Avec une équipe allant de 1 à 20 ans d'expérience, nous avons réalisé des millions. Nous pourrions nous vanter de chiffres ici, mais nous préférons laisser notre travail parler de lui-même. Nos clients? Toujours confidentiels.",
      makePeopleSmile: "Mais surtout, nous avons fait sourire les gens.",
      workWithUs: "Travaillez Avec Notre Équipe Incroyable",
      dontWaste: "Ne perdez pas un instant",
      callUs: "Appelez-nous",
      startProject: "Commencez à écrire votre prochain projet",
      weDo: "NOUS FAISONS",
      worldwide: "Mondialement",
      paidMedia: "Médias Payants",
      creative: "Créativité",
      dataGrowth: "Données.Croissance.",
      whatWeDo: "Ce Que Nous Faisons",
      withYou: "Avec Vous",
      partnersBenefit: "Nos partenaires bénéficient d'une équipe perturbatrice, alimentée par l'IA, composée d'acheteurs de médias, de créatifs et de data scientists qui travaillent ensemble pour transformer à la fois vos médias payants et votre recherche organique. En repoussant les limites conventionnelles, nous dépassons vos objectifs de croissance et redéfinissons ce qui est possible sur tous les canaux numériques."
    }
  },
  de: {
    translation: {
      didYouKnow: "Wussten Sie schon:",
      notificationText: "Wir erschaffen Marken, denen Menschen vertrauen, und Kampagnen, die sie zum Handeln bringen. Wir sorgen dafür, dass sich die Menschen um Ihre Marke kümmern, indem wir die Kraft menschlicher Verbindungen nutzen.",
      readyToFeel: "Sind Sie bereit, Menschen etwas fühlen zu lassen?",
      yes: "Ja",
      step3Message: "beschreiben Sie, was Sie brauchen.",
      welcome: "Das traditionelle digitale Modell",
      welcome2: "funktioniert nicht mehr.",
      RequestaQuote: "Ein Angebot anfordern",
      NextStep: "Nächster Schritt",
      nearLocation: "Ich denke, Sie befinden sich in der Nähe von",
      nearLocation1: "Beginnen Sie dort, einen Unterschied zu machen.",
      funnel: "Vollständiger Trichterservice",
      formtile: "Definieren Sie Ihre Regeln mit KI neu",
      step1Message: "Wie heißen Sie?",
      step2Message: "Vervollständigen Sie das Formular",
      nextStep: "NÄCHSTER SCHRITT",
      requestQuote: "ANGEBOT ANFORDERN",
      thankYouMessage: "Vielen Dank, dass Sie ein Angebot angefordert haben.",
      aiElevate: "Lassen Sie KI-Experten Ihre Strategie optimieren.",
      aiElevate1: "Fordern Sie jetzt Ihr Angebot an und sehen Sie die Ergebnisse!",
      phoneNumber: "Telefonnummer",
      observations: "Beschreiben Sie kurz das Projekt und seine Ziele",
      fullName: "Vollständiger Name",
      loadingMessage: "Ich warte darauf, mehr zu erfahren...",
      successMessage: "Unser Team wird sich bald mit unserem besten Angebot bei Ihnen melden.",
      letsTalk: "Lassen Sie uns sprechen – Keine Zeit zu verlieren!",
      ourPartnerships: "Unsere Partnerschaften",
      achievements: "Was Wir Erreicht Haben",
      achievementsDescription: "Mit einem Team, das zwischen 1 und 20 Jahren Erfahrung hat, haben wir viele Millionen erreicht. Wir könnten hier mit Zahlen prahlen, aber wir lassen lieber unsere Arbeit für sich sprechen. Unsere Kunden? Immer vertraulich.",
      makePeopleSmile: "Aber vor allem haben wir die Menschen zum Lächeln gebracht.",
      workWithUs: "Arbeiten Sie mit unserem erstaunlichen Team",
      dontWaste: "Verlieren Sie keine Zeit",
      callUs: "Rufen Sie uns an",
      startProject: "Beginnen Sie mit der Planung Ihres nächsten Projekts",
      weDo: "WIR MACHEN",
      worldwide: "Weltweit",
      paidMedia: "Bezahlte Medien",
      creative: "Kreativität",
      dataGrowth: "Daten.Wachstum.",
      whatWeDo: "Was Wir Machen",
      withYou: "Mit Ihnen",
      partnersBenefit: "Unsere Partner profitieren von einem disruptiven, KI-gestützten Team aus Medieneinkäufern, Kreativen und Datenwissenschaftlern, die nahtlos zusammenarbeiten, um sowohl Ihre bezahlten Medien als auch Ihre organische Suche zu transformieren. Indem wir konventionelle Grenzen durchbrechen, übertreffen wir Ihre Wachstumsziele und definieren neu, was auf allen digitalen Kanälen möglich ist."
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // Idioma padrão
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React já faz o escape por padrão
    },
  });

export default i18n;
