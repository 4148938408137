import React, { useEffect, useRef } from 'react';
import './App.css';
import cat from '../src/assets/cat.png'
import { FaSpinner } from 'react-icons/fa';

import './i18n'; // Importa a configuração do i18n
import { useTranslation } from 'react-i18next';

 
import { FaArrowRight, FaCheck } from 'react-icons/fa';  
 
import logo from '../src/assets/logoquickflowai.png';
import { FaPhoneAlt } from 'react-icons/fa'; 
 
import { FaIdCard } from 'react-icons/fa';  
 
import { FaUser } from 'react-icons/fa';  
import { FaEnvelope } from 'react-icons/fa';
 
import arrowcta from '../src/assets/arrow-cta.svg';
import { useState } from 'react';
 
import Footer from './Footer';
import NotificationPopUp from './Notification';
 
import BenefitsGrid from './BenefitsGrid';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import flaguk from '../src/assets/uk.png';
import flagpt from '../src/assets/pt.png';


function App() {

  const { t, i18n } = useTranslation(); 
const nomeinp = useRef();
const emailinp = useRef();
const observationinp = useRef();
const zipinp = useRef();
const mobileinp = useRef();
  const [formstep, setFromstep] = useState(1);
 
 // New state to hold the geoIp value
 const [geoIp, setGeoIp] = useState('');

 // Fetch the geo location once the component is mounted
 useEffect(() => {
   const fetchGeoLocation = async () => {
    setGeoIp((await getGeoLocation()).split(',').pop().trim());
     
   };
   fetchGeoLocation();
 }, []);

const [formdata, setFormdata] = useState(
  {
 
    name:'',
email:'',
observation:'',
 
phone:''
  }
);

const marks = [
  
];
function valuetext(value) {
   return `${value}`;
}
const handleSliderChange = (event, newValue) => {
  let oldData={...formdata}
 
  setFormdata(oldData);
};

 
const [showMoreText, setShowMoreText] = useState(false); // State to control visibility

 

const validateform = (e) =>{
  let oldData={...formdata}
  let inp = e.target.name;
  let inpvalue =  e.target.value.slice(0, e.target.maxLength);
  oldData[inp] =inpvalue;
    setFormdata(oldData);
}



const validatezip = (e) => {
  let value = e.target.value.replace(/ /g, "");  
  let inputNumbersOnly = value.replace(/\D/g, "");
  if (inputNumbersOnly.length > 8) {
    inputNumbersOnly = inputNumbersOnly.substr(0, 8);
  }
  const splits = inputNumbersOnly.match(/.{1,4}/g);
   let spacedNumber = "";
  if (splits) {
    spacedNumber = splits.join("-");
  }
  let oldData={...formdata}
  let inp = e.target.name;
  oldData[inp] =spacedNumber;
  if (value.length == 8) {
    mobileinp.current.focus();
  }
    setFormdata(oldData);
};
useEffect(()=>{

 
});
useEffect(() => {
  window.addEventListener('load', handleLoad, { once: true });

  return () => window.removeEventListener('load', handleLoad);
}, []);

const handleLoad = () => {
  sendDataToGoogleSheet({ fromwhere: "load" });
};

const checkempty = (inp) =>{
  if(inp.current.value == '')
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checknumberonly = (inp) =>{
  if(!isNaN(inp.current.value))
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checkemail = (inp) =>{
  const errorMessage = document.getElementById('emaillbl'); 
  var emailregex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if(!emailregex.test(inp.current.value))
    {
      errorMessage.textContent = 'Introduza um endereço de e-mail válido.'; 

      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      errorMessage.textContent = ''; 
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checkmobile = (inp,length) =>{
  const errorMessage = document.getElementById('mobilelbl');
  let value = inp.current.value;
  value = value.replace(/\D/g, '');
let iserror = false;
  if (value.length > 3) {
    iserror = false;
    errorMessage.textContent = '';  
  }else if (value.length === 9 && value[0] !== '9') {
    errorMessage.textContent = 'O número de telefone deve começar com 9.';
    iserror = true;
  } else if (value.length < 9) {
    errorMessage.textContent = 'O número de telefone deve ter exatamente 9 dígitos.';
    iserror = true;
  }
  if(iserror)
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}


const checkpostalcode = (inp, length) =>{
  const errorMessage = document.getElementById("ziplbl");
  let iserror = false;
  if(inp.current.value.length != length)
    {
      errorMessage.textContent = 'O código postal deve ser exactamente 7 dígitos.';
      iserror = true;
    }else{
      iserror = false;
      errorMessage.textContent = '';
    }

  if(iserror)
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}


const checknif = (inp, length) => {
  const errorMessage = document.getElementById("niflbl");
  let iserror = false;
  const nif = inp.current.value;

  // Verifica o comprimento do NIF
  if (nif.length != length) {
    errorMessage.textContent = 'O NIF deve ter exatamente 9 dígitos.';
    iserror = true;
  } else if (!validateNIF(nif)) {
    // Se o comprimento está correto, valida o NIF com as regras adicionais
    errorMessage.textContent = 'O NIF fornecido é inválido.';
    iserror = true;
  } else {
    iserror = false;
    errorMessage.textContent = '';
  }

  if (iserror) {
    inp.current.style.backgroundColor = "white";
    inp.current.classList.add("shadowinput");
    inp.current.focus();
    return false;
  } else {
    inp.current.classList.remove("shadowinput");
    return true;
  }
}

// Função auxiliar para validar o NIF (integrada dentro do checknif)
function validateNIF(nif) {
  // Verifica se o primeiro ou os dois primeiros dígitos são válidos
  if (
    !['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
    !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))
  ) {
    return false;
  }

  // Cálculo do total com base nos primeiros 8 dígitos
  let total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;

  // Calcula o módulo 11
  let modulo11 = total - parseInt(total / 11) * 11;
  let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

  // Verifica se o último dígito corresponde ao comparador
  return nif[8] == comparador;
}


const submitform = (step) => {

  if (!checkempty(nomeinp) ) {
    setFromstep(1)
    return;
  }
 
 
  if (!checkemail(emailinp) ) {
    setFromstep(1)
    return;
  }
 
  if (step == 1) {
    sendDataToGoogleSheet({
      fromwhere: "step1" ,
      name: nomeinp.current.value,
      email: emailinp.current.value,
    });
    setFromstep(2)
  }
  

  document.getElementById('Txt2Step').innerHTML = 
  nomeinp.current.value.split(" ")[0] + ", " + t('step3Message') ;


 
  
  if(formstep == 2){
if(
  checkmobile(mobileinp,9) == true
)
 {
  sendDataToGoogleSheet({
    fromwhere : "step3",
    name: nomeinp.current.value,
    email: emailinp.current.value,
    phone: mobileinp.current.value,
    observations: observationinp.current.value,
  
  }); 

  
  setFromstep(3)
   

}

  }
}



const getGeoLocation = async () => {
  try {
    const response = await fetch('https://ipinfo.io/json');

    if (!response.ok) {
      throw new Error('Failed to fetch IP information');
    }

    const result = await response.json();

    // Use the correct field names returned by ipinfo.io
    if (result && result.country && result.region && result.city) {
      return `${result.country}, ${result.region}, ${result.city}`;
    } else {
      return 'Localização não encontrada';
    }
  } catch (error) {
    console.error('Erro ao obter geolocalização:', error);
    return 'Localização não encontrada';
  }
};



const getIPAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip; // Retorna o IP público
  } catch (error) {
    console.error('Erro ao obter o IP:', error);
    return 'IP Não Disponível';  
  }
};

 




function getSaudacao() {
  const now = new Date();
  const hour = now.getHours();

  if (hour >= 6 && hour < 13) {
    return "Bom dia";
  } else if (hour >= 13 && hour < 20) {
    return "Boa tarde";
  } else {
    return "Boa noite";
  }}


  
  const sendDataToGoogleSheet = async ({
    fromwhere ="",
    name = "",
    email = "",
    phone = "",
   
    
    observations = "N/A",
  
    publisher = "N/A"
  } = {}) => {
    // URL do Google Apps Script com CORS Anywhere como proxy
    const scriptUrl = 'https://script.google.com/macros/s/AKfycbxw4DRBm9A_raXbIBTOk38d9Y7lz9eK38x95y-D9ku9kPtgdizdkiHXRuDjtJHp8FUz/exec';
    const proxyUrl = 'https://corsproxy.io/?';

    

  
  
    let sessionValue = sessionStorage.getItem('user_session');
    let sessionValueGuid = sessionStorage.getItem('user_sessionGuid');
    
    // Verifica e define `user_session`
    if (!sessionValue) {
      sessionValue = `session_${new Date().getTime()}`;  
      sessionStorage.setItem('user_session', sessionValue);  
    }
    
    // Verifica e define `user_sessionGuid` (GUID)
    if (!sessionValueGuid) {
      sessionValueGuid = crypto.randomUUID(); // Gera um GUID único
      sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Armazena o GUID no sessionStorage
    }
  
 
    
    const geoIp = await getGeoLocation();
    const userIP = await getIPAddress();

    // Definir valores padrão internos
    const data = {
      Campaign: 'GetLeads',
      Session: sessionValue, 
      Date: new Date().toISOString(),  
      IP: userIP || 'N/A', 
      Device: navigator.userAgent.includes('Mobile') ? 'Mobile' : 'Desktop',  
      Geo_IP: geoIp,  
      Name: name, 
      Email: email,  
      Phone: phone,  
  
      Observations: observations,
 
      Publisher: publisher ,
      UUID: sessionValueGuid 
    };
  
    try {
     
      const response = await fetch(proxyUrl + scriptUrl, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.text();
      console.log(`Sucess ${result}`);
    } catch (error) {
      console.error(`Error ${error}`);
    }
  };
  

 
  const saudacao = getSaudacao();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    

    
    <>
<div className="w-full bg-[#F0F1F3] p-5 pt-10 pb-10 flex items-center justify-between fixed top-0 left-0 z-50">
  {/* Logo da empresa */}
  <img
    src={logo}
    alt="Logo"
    className="max-w-[200px] lg:max-w-[300px] md:max-w-[250px] lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2"
  />

  {/* Texto de idiomas no canto superior direito */}
  <div className="flex space-x-4 absolute top-5 right-5">
  {/* Texto para Português */}
  <span
    className={`cursor-pointer text-lg font-bold transition-colors duration-200 ${i18n.language === 'pt' ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}
    onClick={() => i18n.changeLanguage('pt')} // Altera para Português
  >
    PT
  </span>
  
  {/* Texto para Inglês */}
  <span
    className={`cursor-pointer text-lg font-bold transition-colors duration-200 ${i18n.language === 'en' ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}
    onClick={() => i18n.changeLanguage('en')} // Altera para Inglês
  >
    EN
  </span>

  {/* Texto para Espanhol */}
  <span
    className={`cursor-pointer text-lg font-bold transition-colors duration-200 ${i18n.language === 'es' ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}
    onClick={() => i18n.changeLanguage('es')} // Altera para Espanhol
  >
    ES
  </span>

  {/* Texto para Francês */}
  <span
    className={`cursor-pointer text-lg font-bold transition-colors duration-200 ${i18n.language === 'fr' ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}
    onClick={() => i18n.changeLanguage('fr')} // Altera para Francês
  >
    FR
  </span>

  {/* Texto para Alemão */}
  <span
    className={`cursor-pointer text-lg font-bold transition-colors duration-200 ${i18n.language === 'de' ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}
    onClick={() => i18n.changeLanguage('de')} // Altera para Alemão
  >
    DE
  </span>
</div>

  {/* Texto ao lado direito */}
  <span className="text-right mr-2 ml-auto">
    <span className="text-sm">{t('funnel')}</span> <br />
  </span>
</div>



<div><br></br><br></br></div>



<div className="w-full flex flex-col lg:flex-row bg-center bg-cover bg-no-repeat text-white pt-16 pb-16 lg:pt-24 lg:pb-24 relative ">
<div className="absolute inset-0 bg-[url('../src/assets/lost-places-pforphoto-leave-factory-158229.webp')] bg-center bg-cover bg-no-repeat pulse-effect"></div>

  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black "></div>
  <div className="relative flex flex-col lg:flex-row w-full z-10 justify-center items-start">

   
  <div className="w-full lg:w-1/2 flex flex-col justify-center items-center lg:justify-center lg:items-center p-5 lg:p-0 pt-[50px] lg:pt-0 h-full">
  <p className="font-bebasneuepro text-5xl lg:text-6xl font-bold text-center mt-5 lg:mt-0" style={{ color: "#CCFE01" }}>
  {t('welcome')}<br />
    <span style={{ color: "rgb(255, 255, 255)", fontSize: "3rem" }}>{t('welcome2')}</span>
  </p>
  <div style={{ 
  textAlign: 'center', 
  fontSize: '24px', 
  fontWeight: 'bold', 
  color: '#FFFFFF', // Cor branca para melhor contraste
  textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)', // Sombra mais suave para destacar
  background: 'linear-gradient(to right, #4B0082, #000080)', 
  padding: '20px', 
  borderRadius: '10px',
  transform: 'rotate(-2deg)',
  border: '5px solid #00ff00'
}}>
{t('nearLocation')}<span style={{ backgroundColor: 'white', color: ' #4B0082', padding: '2px 4px', borderRadius: '3px' }}>{geoIp}</span>. <br></br>
{t('nearLocation1')}
</div>



</div>




    





    <div className='w-full lg:w-auto pb-5 lg:px-16 lg:pb-16' id="askproposal">
 

<p className={`text-white font-bebasneuepro text-base lg:text-3xl font-semibold flex justify-center md:justify-between items-center w-full mt-[20px] pt-[10px] pb-[10px] ${formstep == 3 ? 'hidden' : 'block'}`}>
  {/* Main Text with Icon */}
  <h2 className="w-full text-center text-4xl md:text-6xl font-bold flex justify-center items-center text-white mt-4 p-[10px] lg:p-0">
  {t('formtile')}<br />
  
</h2>

</p>

<div className={`bg-[rgba(255,255,255,0.)] border border-[rgba(255,255,255,0.5)] rounded-xl mx-4 pt-2 px-4 lg:px-12 mb-3 lg:h-[500px] ${formstep > 2 ? 'hidden' : 'block'}`}>

  <div className={`${formstep == 1 ? 'block' :'hidden'}`}>
  <p className='font-bebasneuepro font-semibold text-center py-2 uppercase lg:mb-2 text-black' style={{ fontSize: '24px' }}> 
</p>



    <div className='px-5 relative mb-3'>
    <input 
  type="text" 
  ref={nomeinp} 
  name="name" 
  value={formdata.name} 
  onChange={validateform} 
  className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal`} 
  placeholder={t('step1Message')}  // Remova as aspas simples
  maxLength={100} 
/> 
<span className="absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered"></span>
<FaUser className="absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]" />

    </div>

    <div className='px-5 relative mb-3'>
      <input type='text' ref={emailinp} name="email" value={formdata.email} onChange={validateform} className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='Email' maxLength={100} /> 
      <span id="emaillbl" className='absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered'></span>
      <FaEnvelope className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
    </div>
  </div>

  <div className={`${formstep === 2 ? 'block' :'hidden'}`}>
    <p className='font-bebasneuepro font-semibold text-center py-2 uppercase lg:mb-2' style={{ fontSize: '24px' }} id="Txt2Step">Complete the form</p>

 

    <div className='flex flex-col lg:flex-row mb-1'>
 

      <div className='w-full px-5 relative mb-3'>
        <input type='number' inputmode="numeric" name="phone" ref={mobileinp} value={formdata.phone} onChange={validateform}
         className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal text-black`} 
         placeholder={t('phoneNumber')}  maxLength={9} /> 
        <span id="mobilelbl" className='absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered'></span>
        <FaPhoneAlt className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
      </div>
    </div>

    <div className="px-5 relative mb-3">
  <textarea 
    ref={observationinp} 
    name="observation" 
    value={formdata.observation} 
    onChange={validateform} 
    className="block w-full py-4 pl-12 pr-8 h-32 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal resize-none text-black" 
    placeholder={t('observations')}  // Corrigido: Sem aspas simples
    maxLength={500} 
  /> 
  <span id="niflbl" className="absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered"></span>
  <FaIdCard className="absolute top-[15px] left-9 text-black w-[20px] h-[20px]" />
</div>

  </div>

  <div className="relative w-full flex justify-center items-center">
    {/* Back Button (Left-Aligned, Absolute Position) */}
    <button
      onClick={() => setFromstep(1)}
      className={`absolute left-0 bg-gray-300 hover:bg-gray-400 rounded-full w-[40px] h-[40px] flex justify-center items-center ${formstep === 1 ? 'invisible' : 'visible'}`}
      style={{ marginLeft: '20px' }}
    >
      <img src={arrowcta} alt="Back Arrow" className="w-[10px] rotate-180" />
    </button>

    <button
  onClick={() => {
    if (formstep === 1) {
      submitform(1);
    } else if (formstep === 2) {
      submitform(2);
    }
  }}
  className={`flex items-center justify-center w-[230px] ${formstep === 1 ? 'bg-[#28a745] hover:bg-[#86ad5e]' : 'bg-[#FF5722] hover:bg-[#FF7043]'} text-center rounded-md p-2.5 text-white font-bebasneuepro font-semibold text-lg lg:text-2xl focus:outline-none focus:ring-4 ${formstep === 1 ? 'focus:ring-[#86ad5e]' : 'focus:ring-[#FF7043]'} shadow-lg transition-all duration-200`}
  role="button"
  aria-label={formstep === 1 ? 'Go to the next step' : t('requestQuote')} // Corrigido
>
  {formstep === 1 ? (
    <>
      <FaArrowRight className="mr-2" />
      {t('NextStep')}  {/* Traduzir "REQUEST QUOTE" */}
    </>
  ) : (
    <>
      <FaCheck className="mr-2" />
      {t('requestQuote')}  {/* Traduzir "REQUEST QUOTE" */}
    </>
  )}
</button>


   
  </div>
  {formstep === 1 && (
    <div class="w-full flex items-center justify-center py-2.5 px-2.5">
  <span class="text-white text-xl text-center">
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <FaSpinner style={{ fontSize: '24px', marginRight: '10px', animation: 'spin 1s linear infinite' }} />
    {t('loadingMessage')}<br />
  </div>
  {t('aiElevate')}<br />
  {t('aiElevate1')}<br />
  </span>
</div>

)}


  {formstep < 3 && (
    <div className="flex justify-center pt-5">
      <div className="w-[300px] lg:w-[500px] h-8 bg-gray-300 rounded-full mt-2 mb-[30px]">
        <div className="h-full bg-[#28a745] rounded-full transition-all duration-300" style={{ width: formstep === 1 ? '30%' : formstep === 2 ? '70%' : '0%' }}></div>
      </div>
    </div>
  )}

</div>

{/* Success Messages for Steps */}
<div className={`max-w-[90%] lg:max-w-[65%] pb-48 pt-12 mx-auto ${formstep == 3 ? 'block' :'hidden'} text-white text-center font-bebasneuepro text-2xl lg:text-3xl`}>
  <p className={`text-white text-center font-bebasneuepro text-4xl lg:text-5xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5`}>Hello {formdata["name"]}!</p>
  <p className='uppercase mb-3'>Thank you for requesting a quote.</p>
  <p className='uppercase mb-3'>Our team will get in touch with you soon with our best offer.</p>
 
</div>
</div>


</div>

      
    </div>







 
<BenefitsGrid/>
<div style={{ padding: '20px 0' }}></div>
<NotificationPopUp />
    <Footer />
    </>
   
  );
}

export default App;